<template>
    <div id="ap">
        <template v-if="mode == 'design'">
            <flow-design :data="designRow" :approveId="approveId" @back="() => { mode = 'list'; }"
                         @success="doRefresh()"></flow-design>
        </template>
        <template v-if="mode == 'list'">
            <a-layout>
                <div class="content-header">
                    <a-affix :offset-top="0" style="width: 100%;">
                        <a-row type="flex" justify="space-between">
                            <a-col span="12">
                                <common-page-btn @save="$refs.flowForm.show()" />
                            </a-col>
                            <a-col span="12">
                                <a-row type="flex" align="middle" justify="end" :gutter="3">
                                    <a-col span="12">
                                        <a-input v-model="param.keyword" allowClear search @pressEnter="doRefresh"
                                                 placeholder="关键词查询"
                                                 @change="() => { if (param.keyword == '') { doRefresh(); } }"></a-input>
                                    </a-col>
                                    <!-- <a-col span="6" v-if="getOrganId() == 0">
                                        <a-select v-model="param.organId" style="width:100%"
                                            @change="() => { param.categoryId = ''; doRefresh(); getCategory() }"
                                            placeholder="根据单位筛选">
                                            <a-select-option :value="organ.organId"
                                                v-for="( organ, index ) in  getOrganList() " :key="index">{{ organ.name
                                                }}</a-select-option>
                                        </a-select>
                                    </a-col> -->
                                    <a-col span="6">
                                        <a-select style="width:100%" placeholder="分类筛选" v-model="param.categoryId"
                                                  allowClear @change="doRefresh">
                                            <a-select-option :value="item.categoryId" v-for=" item  in  category "
                                                             :key="item.categoryId">{{
                                                                 item.name }}</a-select-option>
                                        </a-select>
                                    </a-col>
                                </a-row>
                            </a-col>
                        </a-row>
                    </a-affix>
                </div>
            </a-layout>
            <a-layout>
                <a-layout-content>
                    <a-table :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25"
                             :style="{ background: '#FFF' }" size="small" row-key="approveId" border :columns="keys"
                             ref="list" :loading="loading" :data-source="data.records">

                        <!--操作-->
                        <template slot-scope="row" slot="action">
                            <a-button-group shape="circle" size="small">
                                <a-tooltip title="设计流程"><a-button type="link" v-has="'approve:flow:save'" icon="ant-design"
                                              @click="doDesign(row.approveId)"></a-button></a-tooltip>
                                <a-tooltip title="编辑"><a-button type="link" v-has="'approve:save'" icon="edit"
                                              @click="$refs.flowForm.show(row)"></a-button></a-tooltip>
                                <a-tooltip title="删除"><a-button type="link" v-has="'approve:del'" icon="delete"
                                              @click="del(row.approveId)"></a-button></a-tooltip>
                            </a-button-group>
                        </template>
                    </a-table>
                </a-layout-content>
            </a-layout>
            <a-row>
                <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
                    <a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
                                  @showSizeChange="setLimit" :current="data.current" />
                </a-col>
            </a-row>
            <a-back-top />
        </template>


        <flow-form ref="flowForm" @close="getList" :category="category" :organId="param.organId"
                   :categoryId="param.categoryId" @success="(id) => { doRefresh(); doDesign(id); }"></flow-form>

    </div>
</template>

<script>
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import flowForm from './components/flowForm.vue';
import request from '@/config/request';
import flowDesign from './components/flow-design.vue';
export default {
    components: { flowForm, flowDesign },
    data() {
        return {
            loading: false,
            mode: 'list',//list=列表页面,design=流程设计页面
            data: [],
            category: [],
            approveId: '',//操作的流程ID
            param: {
                page: 1,
                limit: 20,
                categoryId: '',
                organId: apiUtil.getDefaultOrganId(),
                keyword: ''
            },
            keys: [
                { title: 'ID', dataIndex: 'approveId', width: 100, align: 'center' },
                { title: '名称', dataIndex: 'name', align: 'left', width: 300, ellipsis: true },
                { title: '说明', dataIndex: 'description', align: 'left', ellipsis: true },
                { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
            ],
            designRow: null
        };
    },
    created() {
        this.getList();
    },
    mounted() {
        this.getCategory();
    },
    methods: {
        ...apiUtil,
        // 设计流程
        doDesign(approveId) {
            utils.showSpin();
            request.get("/platform/approve/detail/" + approveId).then(ret => {
                this.approveId = approveId;
                this.designRow = ret.data;
                this.mode = 'design';
            });
        },
        //删除
        del(id) {
            this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
                utils.showSpin();
                this.http.delete('/platform/approve/del/' + id).then(ret => {
                    if (ret.code == 200) {
                        this.utils.success('操作成功！').then(() => {
                            this.getList();
                        });
                    }
                });
            });
        },
        getCategory() {
            request.get("/platform/approve/category", { organId: this.param.organId }).then(ret => {
                this.category = ret.data;
            });
        },
        //获取表单数据
        doRefresh() {
            this.param.page = 1;
            this.getList();
        },
        // 获取数据
        getList() {
            this.loading = true;
            this.param.categoryId = !utils.isEmpty(this.param.categoryId) ? this.param.categoryId : '';
            this.http
                .get('/platform/approve/list', this.param)
                .then(ret => {
                    this.data = ret.data;
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        //设置每页的显示条数
        setLimit(current, limit) {
            this.param.page = 1;
            this.param.limit = limit;
            this.getList();
        },
        //页码切换
        getNext(page) {
            this.param.page = page;
            this.getList();
        }
    }
};
</script>

<style scoped></style>
